import { useState, useEffect } from 'react';
import { AvailableLoadDetail, AvailableLoadError, AvailableLoadNotFound } from 'shared/models/loads/available-load-detail.model';
import { getShipmentDetailAsync } from '@api/shipments';
import { useLoader } from 'app/hooks/use-loader.hook';
import { ResponseCodes } from '@app/globals/constants';

export const useAvailableLoadDetails = (loadId: number, loaderName: string = null): [AvailableLoadDetail, AvailableLoadNotFound | AvailableLoadError] => {
  const [error, setError] = useState<AvailableLoadNotFound | AvailableLoadError>(null);
  const [shipmentDetail, setShipmentDetail] = useState<AvailableLoadDetail>(null);
  const loader = useLoader(loaderName);

  useEffect(() => {
    setError(e => null);
  }, [loadId]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getShipmentDetailAsync(loadId);
      if (result.success) {
        setShipmentDetail(result.value);
      } else if (result.error.code === ResponseCodes.NOT_FOUND) {
        setError(new AvailableLoadNotFound(loadId));
      } else {
        setError(new AvailableLoadError(result.error, loadId));
      }
    };

    if (loadId) {
      loader.show();
      fetchData()
        .catch(err => {
          setError(err);
        })
        .finally(() => loader.hide());
    }
  }, [loadId]);

  return [shipmentDetail, error];
};
