import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { useTimer } from 'app/hooks/use-timer.hook';
import { OfferState } from '../hooks/use-carrier-offer.hook';
import { updateOfferStatus } from 'shared/find-loads/redux/find-loads.actions';
import { updateOfferStatusOnOffersStore } from 'shared/offers/redux/offers.actions';
import { OfferStatus } from 'shared/enums/offer-status.enum';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';

export interface OfferExpirationTimerProps {
  offerState: OfferState;
  loadNumber: number;
}

export const OfferExpirationTimer = ({ offerState, loadNumber }: OfferExpirationTimerProps) => shouldShowTimer(offerState) && <OfferExpirationTimerComponent offerState={offerState} loadNumber={loadNumber} />;

const OfferExpirationTimerComponent = ({ offerState, loadNumber }: OfferExpirationTimerProps) => {
  const dispatch = useDispatch();
  const timeLeft = useTimer({
    timerExpirationDate: offerState?.offerStatus?.offerExpirationDate,
    timerFormat: { hourLabel: 'h', minuteLabel: 'm', secondLabel: 's' },
    onTimeout: () => {
      dispatch(updateOfferStatus({
        loadNumber: loadNumber,
        updatedStatus: AvailableLoadOfferStatus.EXPIRED,
        offerId: offerState?.carrierOffer?.offerId,
        bookOfferId: offerState?.carrierOffer?.bookOfferId,
      }));
      dispatch(updateOfferStatusOnOffersStore({
        loadNumber: loadNumber,
        offerId: offerState?.carrierOffer?.offerId,
        bookOfferId: offerState?.carrierOffer?.bookOfferId,
        updatedStatus: OfferStatus.EXPIRED,
      }));
    }
  });
  return <Trans i18nKey="EXPIRES_IN_TEXT" components={[<span className="time-left">{timeLeft}</span>]} />;
};

const shouldShowTimer = (offerState: OfferState): boolean => {
  const hasOffer: boolean = Boolean(offerState?.carrierOffer);
  const { offerStatus } = offerState ?? {};
  return hasOffer
    && (offerStatus?.isOfferAcceptedByDataScience || offerStatus?.isOfferCountered)
    && !offerStatus?.isOfferExpired;
};
