import { buildUrl, get } from '@lib/api';
import Result from "@lib/result";
import { AvailableLoadDetail } from 'shared/models/loads/available-load-detail.model';

const baseUrl = apiConfig.shipmentsAPI;

export async function getShipmentDetailAsync(shipmentId: number): Promise<result<AvailableLoadDetail>> {
  const result = await get<AvailableLoadDetailJSON>(buildUrl(baseUrl, `/v1/shipments/${shipmentId}`));
  return result.success
    ? Result.Success(new AvailableLoadDetail(result.value))
    : Result.Fail(result.error);
}

export default {
  getShipmentDetailAsync
};