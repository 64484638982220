interface Props {
  offerCountered: boolean;
}

export const OfferIcon = ({ offerCountered }: Props) => {
  if (offerCountered) {
    return <img className="countered-offer-icon" src="/assets/images/find-loads-countered-offer.svg" data-testid="offer-icon" />;
  } else {
    return <img className="accepted-offer-checkmark" src="/assets/images/find-loads-accepted-offer-checkmark.svg" data-testid="offer-icon" />;
  }
};
